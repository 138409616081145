.about {
    text-align: center;
}

.image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
}

.description {
    text-align: center;
}