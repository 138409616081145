.search__ul {
  list-style-type: none;
  padding: 0;
}

.Search {
  text-align: center;
}

.resultPage {
  display: block;
}
 
h4 {
  font: bold 20px/1.5 Helvetica, Verdana, sans-serif;
  margin: 0;
}

.info {
  display: flex;
  justify-content: center;
}

.price, .rating {
  margin: 10px
}

.adding {
  justify-content: space-around;
  position: relative;
  margin: 0 75px 0 75px;
}

.address {
  margin: 0;
}
 
.search__list img {
  float: left;
  margin: 0 15px 0 0;
}
 
.search__list p {
  font: 200 12px/1.5 Georgia, Times New Roman, serif;
}
 
.search__list {
  padding: 10px;
  overflow: auto;
  list-style-position: inside;
  border: 1px solid black;
  background: #6096BA;
}

.search__list:nth-child(odd) {
    background: #011638
}

/* margin: 0 375px 0 375px; */