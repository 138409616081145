
.NavCircleButton {
  background-color: #cacaca;
  border-radius: 10px;
  border: 0;
  color: black;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  width: 100px;
}

a.NavCircleButton {
  margin: 0 0 10px 0;
  padding: 8px 8px 8px;
}

.NavCircleButton:hover {
  cursor: pointer;
}
