body {
    background-color: #274c77;
    font-family: sans-serif;
    color: white;
}

#container {
    display: flex;
    justify-content: center;
}

img {
    height: 250px;
    width: 85%;
    padding: 25px 25px 10px 25px;
}

@media screen and (min-width: 700px) {

    .HomePage {
        display: flex;
        justify-content: space-between;
    }

    .description {
        display: flex;
        align-items: center;
        padding: 0 100px 0 50px
    }

    img {
        height: 300px;
        width: 300px;
    }
  }