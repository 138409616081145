.lists {
    text-align: center;
}

.lists__ul {
    list-style-type: none;
    padding: 0;
}

h3 {
    font: bold 20px/1.5 Helvetica, Verdana, sans-serif;
}

.lists__list {
    list-style-type: none;
    padding: 20px;
    overflow: auto;
    list-style-position: inside;
    border: 1px solid black;
    background: #6096BA;
}

.list_link {
    display: block;
    float: left;
}

a:link {
    text-decoration: none;
}

.lists__list:nth-child(odd) {
    background: #011638
}

.delete__list {
    display: block;
    float: right;
}

.list-num-bars {
    color: #d5e2fd;
    display: inline;
    float: left;
    font-size: 10px;
}
